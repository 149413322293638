<template>
  <div class="wrap" id="checkInfo">
    <Stepper :step="1" />
    <div class="card orderInfo" v-if="productDetail">
      <div class="card_title">訂單內容</div>
      <div class="orderInfo_commodity" v-if="buyType == 1">
        <span>贈</span> {{ productDetail.Name }} <span>給</span>
        <br class="hidden-mobile-up" />朋友
        <span>(ID:{{ receiverMemberId }})</span>
      </div>
      <div class="orderInfo_commodity" v-else-if="buyType == 2">
        {{ productDetail.Name }}兌換碼
      </div>
      <div class="orderInfo_commodity" v-else>
        {{ productDetail.Name }}
      </div>
      <div class="orderInfo_buyDetail">
        <div class="orderInfo_buyDetail__item">
          適用
          <span>{{ productDetail.Appliance }}</span>
        </div>
        <div class="orderInfo_buyDetail__item">
          數量
          <span>{{ butQuantity }}組</span>
        </div>
      </div>

      <div class="orderInfo_price">
        <div class="price">
          <span>NT.</span>
          <span>{{ productDetail.Amount * butQuantity }}</span>
        </div>
        <div class="payMethods">
          <img src="../../assets/占星小鋪/cc-visa.svg" alt="" />
          <img src="../../assets/占星小鋪/master.svg" alt="" />
          <img src="../../assets/占星小鋪/Vector.svg" alt="" />
        </div>
      </div>
      <div class="orderInfo_detail">
        <div class="orderInfo_detail_title" @click="openDetail = !openDetail">
          <div><span></span><span v-show="!openDetail"></span></div>
          <h3>注意事項</h3>
        </div>
        <div
          class="orderInfo_detail_content"
          :class="{ open: openDetail }"
          v-html="productDetail.Notice"
        ></div>
      </div>
    </div>
    <div class="card userInfo">
      <div class="card_title mb-7">基本資料</div>
      <label for="name" class="inputItem">
        <span>姓名</span>
        <input
          type="text"
          id="name"
          placeholder="請輸入與信用卡相同的姓名"
          v-model.trim="name"
        />
      </label>
      <label for="tel" class="inputItem">
        <span>電話</span>
        <input
          type="tel"
          id="tel"
          placeholder="請輸入電話"
          v-model.trim="phone"
        />
      </label>
      <label for="email" class="inputItem">
        <span>信箱</span>
        <input
          type="email"
          id="email"
          placeholder="請輸入信箱"
          v-model.trim="email"
        />
      </label>
      <div class="saveInfo" @click="saveInfo = !saveInfo">
        <span
          ><svg
            v-show="saveInfo"
            width="12"
            height="10"
            viewBox="0 0 12 10"
            fill="#f00"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M11.8992 1.39981L5.03808 9.73314L4.10162 9.76547L0.129395 5.56794L1.03731 4.70876L4.52299 8.39216L10.9342 0.605286L11.8992 1.39981Z"
              fill="#9B9B9B"
            />
          </svg>
        </span>
        記住本次基本資訊
      </div>
    </div>
    <div class="nextBtn" :class="{ notReady: !verify }" @click="toNext()">
      下一步
    </div>

    <!-- <FixedPopup v-show="again_confirm">
      <div class="again_confirm">
        <div class="closeBar hidden-mobile-up">
          <h3>驗證信箱</h3>
        </div>
        <img src="@/assets/訂單流程/confirmEmail.svg" alt="" />
        <span>信箱驗證失敗，<br />請再輸入一次！</span>
        <label for="email2" class="inputItem">
          信箱
          <input
            type="email"
            id="email2"
            placeholder="請輸入信箱"
            v-model.trim="email"
          />
        </label>
        <div class="btn_group">
          <div
            class="cancel_btn hidden-mobile-down"
            @click="again_confirm = false"
          >
            取消
          </div>
          <div
            class="confirm_btn"
            :class="{ notReady: !verify }"
            @click="open_confirmEmail()"
          >
            重新驗證
          </div>
        </div>
      </div>
    </FixedPopup>
    <FixedPopup v-show="confirmResult_pass">
      <div class="confirmResultBox">
        <img src="@/assets/訂單流程/ok.svg" alt="" />
        <span>信箱已驗證成功，謝謝您！</span>
      </div>
    </FixedPopup>
    <FixedPopup v-show="confirmResult_fail">
      <div class="confirmResultBox">
        <img src="@/assets/訂單流程/fail.svg" alt="" />
        <span>驗證碼錯誤，請再輸入一次！</span>
      </div>
    </FixedPopup> -->
  </div>
</template>

<script>
// import commodityList from "../../js/commodityList";
import Stepper from "../../components/訂單流程/Stepper";
// import ConfirmEmail from "../../components/訂單流程/ConfirmEmail";
// import FixedPopup from "../../components/Fixed_popup";
export default {
  components: { Stepper },
  data() {
    return {
      name: "",
      phone: "",
      email: "",
      openDetail: false,
      openConfirmEmail: false,
      saveInfo: true,
      confirmResult_pass: false,
      confirmResult_fail: false,
      again_confirm: false,
      productDetail: {},
    };
  },
  async created() {
    if (!this.$store.state.orderCreater.ProductID) {
      return this.$router.replace("/");
    }
    await this.getProductDetail();
    this.setDefault();
  },
  computed: {
    verify() {
      return (
        this.name &&
        this.phone &&
        this.email.match(
          /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/
        )
      );
    },
    butQuantity() {
      return this.$store.state.orderCreater.Quantity;
    },
    receiverMemberId() {
      return this.$store.state.orderCreater.ReceiverMemberId;
    },
    buyType() {
      return this.$store.state.orderCreater.Type;
    },
    userName() {
      if (this.$store.state.userData) {
        let self = this.$store.getters.SELF_OBJ;
        return self.Name;
      }
      return "";
    },
  },
  methods: {
    async getProductDetail() {
      try {
        let res = await this.$API.GET_ProductDetail(
          this.$store.state.orderCreater.ProductID
        );
        this.productDetail = res.data.Data;
      } catch (e) {
        console.error(e);
      }
    },
    toNext() {
      if (!this.verify) return alert("請輸入正確的格式");
      this.confirmResult_pass = true;

      let orderObj = this.$store.state.orderCreater;
      orderObj.Email = this.email;
      orderObj.Name = this.name;
      orderObj.Phone = this.phone;
      orderObj.IsSaveBasic = this.saveInfo;
      orderObj.Amount = this.productDetail.Amount * this.butQuantity;
      this.$store.commit("updateOrderCreater", orderObj);
      this.$router.push("/order/method");
    },
    setDefault() {
      let userData = this.$store.state.userData;

      this.name = this.userName;
      this.phone = userData.Phone;
      this.email = userData.Email;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixin.scss";
.wrap#checkInfo {
  padding-top: 40px;
  margin: 0 auto;
  max-width: 600px;
  @include mobile {
    width: 100%;
    padding: 40px 18px;
  }
  .card {
    margin-top: 86px;
    padding: 60px 95px;
    width: 100%;
    background: #f0f2f4;
    box-shadow: -4px -4px 16px #ffffff, -4px -4px 10px #ffffff,
      4px 4px 25px rgba(0, 0, 0, 0.3);
    border-radius: 100px;
    @include mobile {
      padding: 60px 40px;
    }
    .card_title {
      font-weight: 700;
      font-size: 28px;
      line-height: 40px;
      text-align: center;
      letter-spacing: 0.08em;
      color: $theme-color-2;
    }
  }
}
.wrap#checkInfo .orderInfo {
  .orderInfo_commodity {
    font-size: 24px;
    line-height: 36px;
    font-weight: 700;
    color: $secondary-color-1;
    margin-top: 24px;
    @include mobile {
      margin-top: 30px;
    }
    span {
      font-size: 16px;
      display: inline-block;
      margin: 0.5em;
      &:nth-of-type(1) {
        margin-left: 0;
      }
      @include mobile {
        font-size: 14px;
      }
    }
  }
  .orderInfo_buyDetail {
    display: flex;
    gap: 40px;
    &__item {
      margin-top: 20px;
      font-size: 18px;
      line-height: 26px;
      color: #9b9b9b;
      span {
        padding-left: 10px;
        font-size: 18px;
        line-height: 26px;
        color: $secondary-color-1;
      }
    }
  }
  .orderInfo_price {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
    margin-bottom: 12px;
    margin-top: 20px;
    padding-bottom: 15px;
    border-bottom: 1.5px solid #d9d9d9;
    .price {
      display: flex;
      align-items: flex-end;
      font-weight: 700;
      color: $secondary-color-1;
      & > span:nth-of-type(1) {
        font-size: 16px;
        padding-right: 4px;
      }
      & > span:nth-of-type(2) {
        font-size: 40px;
        line-height: 1;
      }
    }
    .payMethods {
      display: flex;
      > img {
        height: 16px;
        margin-left: 6px;
      }
    }
  }
  .orderInfo_message {
    font-size: 16px;
    line-height: 20px;
    text-align: justify;
    color: $theme-color-2;

    margin-bottom: 30px;
    @include mobile {
      margin-bottom: 20px;
    }
  }
  .orderInfo_detail {
    margin-top: 53px;
    .orderInfo_detail_title {
      display: flex;
      align-items: center;
      cursor: pointer;
      h3 {
        padding-left: 14px;
        font-size: 20px;
        line-height: 36px;
        color: #9b9b9b;
        font-weight: 700;
      }
      div {
        width: 32px;
        height: 32px;
        background: #f0f2f4;
        box-shadow: -2px -2px 4px #ffffff, -2px -2px 4px #ffffff,
          4px 4px 10px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        position: relative;
        span {
          display: inline-block;
          height: 2px;
          width: 16px;
          background-color: $theme-color-2;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          &:nth-of-type(2) {
            transform: translate(-50%, -50%) rotate(90deg);
          }
        }
      }
    }
    .orderInfo_detail_content {
      margin-top: 22px;
      font-size: 16px;
      line-height: 20px;
      text-align: justify;
      color: #9b9b9b;
      height: 0;
      visibility: hidden;
      &.open {
        height: auto;
        visibility: visible;
      }
      .third-title {
        background-color: rgb(244, 244, 200);
      }
    }
  }
}
.wrap#checkInfo .userInfo {
  @include mobile {
    box-shadow: none;
    border-radius: 0;
    padding: 0 40px;
  }

  .saveInfo {
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
    span {
      width: 22px;
      height: 22px;
      background: #f0f2f4;
      box-shadow: -1px -1px 4px #ffffff, 2px 2px 8px rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      @include center;
      margin-right: 10px;
    }
    padding-left: 32px;
    font-size: 18px;
    letter-spacing: 0.06em;
    color: #9b9b9b;
  }
}
.inputItem {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 20px;
  line-height: 30px;
  color: #9b9b9b;
  margin-bottom: 30px;
  @include mobile {
    justify-content: flex-start;
  }
  span {
    width: 50px;
    flex-shrink: 0;
  }
  input {
    flex-grow: 1;
    margin-left: 19px;
    border: none;
    border-bottom: 1px solid #9b9b9b;
    outline: none;
    padding: 4px 0;
    font-size: 20px;
    line-height: 30px;
    color: $secondary-color-1;
    @include mobile {
      width: 188px;
      flex-grow: 0;
    }
    &::placeholder {
      color: #d9d9d9;
    }
    &:focus {
      outline: none;
      border: none;
      border-bottom: 1px solid #9b9b9b;
    }
  }

  p {
    margin: 0;
    padding-left: 19px;
    font-size: 16px;
  }
}
.wrap#checkInfo .nextBtn {
  margin: 40px auto;
  @include btn-default(250px, 56px, $tw_font);
}
.confirmResultBox {
  width: 320px;
  height: 224px;
  background: #f0f2f4;
  box-shadow: -4px -4px 16px #ffffff, -4px -4px 10px #ffffff,
    4px 4px 25px rgba(0, 0, 0, 0.3);
  border-radius: 40px;
  @include center;
  flex-direction: column;
  font-size: 16px;
  line-height: 26px;
  color: $theme-color-2;
  img {
    width: 64px;
    margin-bottom: 16px;
  }
}
.again_confirm {
  width: 472px;
  height: 423px;
  background: #f0f2f4;
  box-shadow: -4px -4px 16px #ffffff, -4px -4px 10px #ffffff,
    4px 4px 25px rgba(0, 0, 0, 0.3);
  border-radius: 40px;
  @include center;
  flex-direction: column;
  font-size: 16px;
  line-height: 26px;
  color: $theme-color-2;
  padding: 0 40px;
  & > span {
    text-align: center;
    font-weight: 700;
  }
  @include mobile {
    width: 100%;
    border-radius: 0;
    height: 100%;
    padding: 0;
    justify-content: flex-start;
  }
  .closeBar {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px;
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    color: $secondary-color-1;
    background-color: #d9d9d9;
  }
  & > label {
    margin-top: 20px;
    @include mobile {
      padding: 0 63px;
      margin-top: 40px;
      margin-bottom: 110px;
    }
  }
  img {
    width: 64px;
    margin-bottom: 16px;
    @include mobile {
      margin-top: 134px;
      margin-bottom: 30px;
    }
  }
  .btn_group {
    width: 100%;
    display: flex;
    justify-content: space-between;
    @include mobile {
      justify-content: center;
    }
    .cancel_btn {
      @include btn(174px, 54px, transparent, #d9d9d9);
      border: 1px solid #d9d9d9;
    }
    .confirm_btn {
      @include btn-default(176px, 56px);
      @include mobile {
        @include btn-default(250px, 56px);
      }
    }
  }
}
input::placeholder {
  @include mobile {
    font-size: 14px;
  }
}
</style>
